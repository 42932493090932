// import "./courses.scss";
import React from "react";

import CheckOutCard from "./CheckOutCard";

export default function CoursesGroup1(props) {
  return (
    <div
      className={
        "ts-courses-group " +
        (props.groupCol === "2" ? "ts-courses-group--2-cards" : "")
      }
    >
      {props.products.map((product, index) => {
        return (
          <CheckOutCard
            rtlSwitch={props.rtlSwitch}
            product={product}
            key={index}
            removeFromCart={props.removeFromCart}
            rateInUsd={props.rateInUsd}
            paypalRate={props.paypalRate}
            total_fees={props.total_fees}
            total_fees_sales={props.total_fees_sales}
          />
        );
      })}
    </div>
  );
}
