import "./cards.scss";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Stack from "react-bootstrap/Stack";

// data
import localData from "data/data.json";
let pageData = localData.check_out_card;

export default function CheckOutCard(props) {

  const [oldPrice, setOldPrice] = useState(0);
  const [newPrice, setNewPrice] = useState(0);

  useEffect(() => {

    let courseSaleFees = 0;
    let courseFees = 0;

    for ( let course in props.product.courses ) {
      courseSaleFees += props.product.courses[course].course_fees[0].sale_fee;
      courseFees += props.product.courses[course].course_fees[0].fee;
    }

    setOldPrice(courseFees);
    setNewPrice(courseSaleFees);

  }, []);

  return (
    <div className="ts-checkout-card  ts-rounded--not-top-right shadow" style={{ height: 'auto' }}>
      <button
        className="btn border-0 p-0 ms-auto"
        onClick={() => props.removeFromCart(props.product.id)}
      >
        <img
          width="25"
          src="/assets/icons/Icon-material-delete-forever-primary.svg"
          alt="..."
        />
      </button>
      <div className="w-100 mt-auto">
        <Stack direction="horizontal" gap={3} className="mb-02 flex flex-column">
          <div className="w-full m-auto p-10 flex justify-content-center align-items-center mt-4">
            <img src={`https://admin.reham.com/uploads/images/program/${props.product.thumb_image}`} style={{ maxWidth: '100%', height: '12rem'}}/>
          </div>
          <div>
            <h6 className="mt-4 mb-4">
              {props.rtlSwitch ? props.product.name_ar : props.product.name_en}
            </h6>
          </div>
          <Stack direction="horizontal" gap={1}>
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
            <img width="15" src="/assets/icons/star.svg" alt="..." />
          </Stack>
        </Stack>
        {/* <p
          className="mb-04"
          dangerouslySetInnerHTML={{
            __html: props.rtlSwitch
              ? props.product.description_ar
              : props.product.description_en,
          }}
        ></p> */}
        <Stack direction="horizontal" gap={3} className="mb-02">
          <h6>
            <del>
              {(oldPrice * (props.rateInUsd ? props.paypalRate : 1)).toFixed(2)}{props.rateInUsd ? "USD" : "KD"}
            </del>
          </h6>
          <h6 variant="success" className="text-success">
            {(newPrice * (props.rateInUsd ? props.paypalRate : 1)).toFixed(2)}{props.rateInUsd ? "USD" : "KD"}
          </h6>
        </Stack>
        <Link
          className="ts-ceckout-card__btn btn btn-primary-07 btn--border-white w-100 fw-bold"
          to={"/program-detail/" + props.product.id}
        >
          {props.rtlSwitch ? pageData.cta_button_ar : pageData.cta_button_en}
        </Link>
      </div>
    </div>
  );
}
